<template>
  <QuickPreviewView
    v-if="previewInvoice && previewInvoice.key"
  >
    <template v-slot:left>
      <ul class="item-box">
        <template v-if="!$route.query.showProjectInfo && !$route.query.search">
          <li
            v-for="invoice in activeItems"
            :key="invoice.key"
            @click="showPreviewInvoice(invoice)"
            :class="{active: invoice.key === previewInvoice.key}"
          >
            {{invoice.project.name}}
          </li>
        </template>
        <template v-else-if="$route.query.search">
          <li
            v-for="invoice in activeItems"
            :key="invoice.key"
            @click="showPreviewInvoice(invoice , true)"
            :class="{active: invoice.invoice_number === previewInvoice.key}"
          >
            {{invoice.project_name}}
          </li>
        </template>
        <template v-else>
          <li
            v-for="invoice in activeItems"
            :key="invoice.key"
            @click="showPreviewInvoice(invoice , true)"
            :class="{active: invoice.key === previewInvoice.key}"
          >
            {{invoice.project.name}}
          </li>
        </template>
      </ul>
    </template>
    <template v-slot:title>
      <h1 v-if="!$route.query.showProjectInfo">
        {{formatCurrency(previewInvoice.grand_total, previewInvoice.currency, true)}}
        - {{previewInvoice.project.name}}</h1>
      <h1 v-else>{{previewInvoice.project.name}}</h1>
      <div class="tools" v-if="$route.query.showProjectInfo">
        <router-link
          class="btn"
          :to="{name: 'ProjectPage', params: {projectKey: previewInvoice.project.key}}"
        >Open</router-link>
        <button
          @click="hidePreviewInvoice"
          class="btn"
        >X</button>
      </div>
      <div class="tools" v-else>
        <router-link
          class="btn"
          :to="{
            name: 'invoice',
            params: { invoicekey: previewInvoice.key, projectKey: previewInvoice.project.key } }"
        >Open</router-link>
        <button
          @click="hidePreviewInvoice"
          class="btn"
        >X</button>
      </div>
    </template>
    <template v-slot:content>
      <InvoicePreview
        :invoiceKey="previewInvoice.key"
        :info="previewInvoice"
      />
    </template>
  </QuickPreviewView>
</template>
<script>
import moment from 'moment';
import { currencyMethodsMixin } from '@/mixin/currency';
import {
  ref,
  watch,
  onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  InvoiceReport,
  ProjectList,
} from '@/store/modules/admin';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import InvoicePreview from '@/components/admin/InvoicePreview.vue';

export default {
  mixins: [currencyMethodsMixin],
  components: {
    QuickPreviewView,
    InvoicePreview,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = ref(new RequestState());
    let month = ref({}) || route.query.month;
    let year = ref({}) || route.query.year;
    const currentDay = moment();
    const prevMonth = ref(null);
    const nextMonth = ref(null);
    const fulfilledItems = ref([]);
    const notFulfilledItems = ref([]);
    const cancelledItems = ref([]);
    const activeItems = ref([]);
    const previewInvoice = ref({});

    const showPreviewInvoice = (invoice, val) => {
      let showProjectInfo;
      route.query = showProjectInfo;
      route.query.showProjectInfo = val;
      previewInvoice.value = invoice;
      if (route && route.params && route.params.type) {
        router.push({
          name: 'adminQuickInvoicePreview',
          params: {
            type: route.params.type,
            key: invoice.key,
          },
          query: {
            year,
            month,
          },
        });
      } else if (route.query.search) {
        router.push({
          name: 'adminInvoicePreview',
          params: {
            key: invoice.invoice_number,
          },
          query: {
            search: route.query.search,
          },
        });
      } else {
        router.push({
          name: 'adminQuickInvoicePreview',
          params: {
            type: 'paidInvoices',
            key: invoice.key,
          },
          query: {
            year,
            month,
          },
        });
      }
    };

    const showPreviewInvoiceUsingKey = (key) => {
      InvoiceReport.fetchInvoice(key).then((response) => {
        showPreviewInvoice(response);
      });
    };

    const hidePreviewInvoice = () => {
      previewInvoice.value = {};
      router.push({
        name: 'invoiceList',
      });
      store.commit('admin/updatePreviewInvoice', { invoice: null });
      return false;
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      if (route.query.search) {
        return ProjectList.searchQuery(route.query.search, 'invoice').then((list) => {
          activeItems.value = list.data.data.docs;
        });
      }
      if (route.query.month && route.query.year) {
        year = route.query.year;
        month = route.query.month;
      } else {
        year = currentDay.year();
        month = currentDay.format('MM');
      }
      return InvoiceReport.fetch(year, month).then((_report) => {
        fulfilledItems.value = [..._report.fulfilled.items];
        notFulfilledItems.value = [..._report.notFulfilled.items];
        cancelledItems.value = [..._report.cancelled.items];
        if (route.params.type === 'unpaidInvoices') {
          activeItems.value = notFulfilledItems.value;
        } else if (route.params.type === 'paidInvoices') {
          activeItems.value = fulfilledItems.value;
        } else {
          activeItems.value = cancelledItems.value;
        }
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const onInvoiceClick = (invoice) => {
      showPreviewInvoiceUsingKey(invoice.key);
    };

    const fetchInvoice = () => {
      InvoiceReport.fetchInvoice(route.params.key).then((response) => {
        previewInvoice.value = response;
      });
    };

    onMounted(() => {
      if (route.params.type || route.query.search) {
        fetchData();
        fetchInvoice();
      }
    });

    watch(() => route.params.key, () => {
      if (route.params.key) {
        fetchData();
        fetchInvoice();
      }
    });

    return {
      state,
      currentDay,
      prevMonth,
      nextMonth,
      activeItems,
      onInvoiceClick,
      previewInvoice,
      showPreviewInvoiceUsingKey,
      showPreviewInvoice,
      hidePreviewInvoice,
      month,
      year,
    };
  },
};
</script>
